/* Description: Master CSS file */

/*****************************************
Table Of Contents:
- General Styles
- Navigation
- Header
- Features
- Details 1
- Details Modal
- Details 2
- Testimonials
- Invitation
- Pricing
- Questions
- Footer
- Copyright
- Back To Top Button
- Extra Pages
- Media Queries
******************************************/

/*****************************************
Colors:
- Backgrounds - light gray #f9f8f8  light orange: #f9f8f8;
- Buttons and Nav Links - orange #fe6655  blue #007bff
- Headings text - default Dark #263238
- Body text - dark gray #565578


Primary #329ef7
Secondary #42cfa5
Info #11cdef
Success #39d5c5
Danger #f5365 #B40404
Warning #fe6655
Default #263238


******************************************/


/**************************/
/*     General Styles     */
/**************************/
body,
html {
    width: 100%;
	height: 100%;
}

body, p {
	color: #565578; 
	font: 400 1rem/1.625rem "Nunito", sans-serif;
}

h1, .h1 {
	color: #263238;
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 3.25rem;
	letter-spacing: -0.4px;
}

h2, .h2 {
	color: #263238;
	font-weight: 600;
	font-size: 2rem;
	line-height: 2.625rem;
	letter-spacing: -0.4px;
}

h3, .h3 {
	color: #263238;
	font-weight: 600;
	font-size: 1.75rem;
	line-height: 2.25rem;
	letter-spacing: -0.2px;
}

h4, .h4 {
	color: #263238;
	font-weight: 600;
	font-size: 1.5rem;
	line-height: 2.125rem;
	letter-spacing: -0.2px;
}

h5, .h5 {
	color: #263238;
	font-weight: 600;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

h6, .h6 {
	color: #263238;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.375rem;
}

.nowrap {
	white-space: nowrap;
  }

.tx-primary {
	color:#329ef7;
}

.tx-secondary {
	color:#42cfa5;
}

.tx-info {
	color: #11cdef;
}
.tx-success {
	color: #39d5c5;
}
.tx-red{
	color:#B40404;
}

.tx-teal {
	color: #01a89e;
}
.tx-danger {
	color: #fe6655;
}
.tx-warning {
	color: #ffc107;
}
.tx-default {
	color: #263238;
}

.tx-white {
	--bs-text-opacity: 1;
	color: #FFFFFF;
  }
.trending-header {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.p-small {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.tx-22 {
	font-size: 2rem;
}

.pd-7{
	padding-top: 7.5rem !important;
}


.testimonial-text {
	font-style: italic;
}

.testimonial-author {
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.625rem;
}

.li-space-lg li {
	margin-bottom: 0.5rem;
}

a {
	color: #565578;
	text-decoration: underline;
}

a:hover {
	color: #565578;
	text-decoration: underline;
}

.no-line {
	text-decoration: none;
}

.no-line:hover {
	text-decoration: none;
}

.blue {
	color: #329ef7;
}

.orange {
	color: #fe6655;
}

.bg-teal {
	background-color: #01a89e;
}

.bg-gray {
	background-color: #f9f8f8;
}

.bg-white-transparent {
	background-color: rgb(255, 255, 255, 0.8);
}

.bg-dark-transparent {
	background-color: rgba(23, 20, 20, 0.6);
}

.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0;
  }

.btn-solid-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #263238;
	border-radius: 30px;
	background-color: #263238;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-reg:hover {
	background-color: transparent;
	color: #263238; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #263238;
	border-radius: 30px;
	background-color: #263238;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-lg:hover {
	background-color: transparent;
	color: #263238; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #263238;
	border-radius: 30px;
	background-color: #263238;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-sm:hover {
	background-color: transparent;
	color: #263238; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-primary-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #329ef7;
	border-radius: 30px;
	background-color: #329ef7;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-primary-sm:hover {
	background-color: transparent;
	color: #329ef7; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-outline-primary {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #329ef7;
	border-radius: 30px;
	background-color: transparent;
	color: #329ef7;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-primary:hover {
	background-color: #329ef7;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-primary-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #329ef7;
	border-radius: 30px;
	background-color: transparent;
	color: #329ef7;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-primary-lg:hover {
	background-color: #329ef7;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-primary-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #329ef7;
	border-radius: 30px;
	background-color: transparent;
	color: #329ef7;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-primary-sm:hover {
	background-color: #329ef7;
	color: #ffffff;
	text-decoration: none;
}

.btn-secondary-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #263238;
	border-radius: 30px;
	background-color: #263238;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-secondary-reg:hover {

	background-color: transparent;
	color: #01a89e;; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-secondary-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #01a89e;
	border-radius: 30px;
	background-color: #01a89e;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-secondary-sm:hover {
	background-color: transparent;
	color: #01a89e; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-outline-secondary-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #01a89e;
	border-radius: 30px;
	background-color: transparent;
	color: #01a89e;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-secondary-sm:hover {
	background-color: #01a89e;
	color: #ffffff;
	text-decoration: none;
	border: 1px solid #01a89e;
}

.btn-outline-secondary-sm.active {
	background-color: #01a89e;
	color: #ffffff;
	text-decoration: none;
	border: 1px solid #01a89e;
}

.btn-outline-secondary {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #01a89e;
	border-radius: 30px;
	background-color: transparent;
	color: #01a89e;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-secondary:hover {
	background-color: #01a89e;
	color: #ffffff;
	text-decoration: none;
	border: 1px solid #01a89e;
}

.btn-outline-secondary-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #01a89e;
	border-radius: 30px;
	background-color: transparent;
	color: #01a89e;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-secondary-lg:hover {
	background-color: #01a89e;
	color: #ffffff;
	text-decoration: none;
	
}

.btn-outline-secondary-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #01a89e;
	border-radius: 30px;
	background-color: transparent;
	color: #01a89e;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-secondary-sm:hover {
	background-color: #01a89e;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-warning {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #fe6655;
	border-radius: 30px;
	background-color: transparent;
	color: #fe6655;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-warning:hover {
	background-color: #fe6655;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #fe6655;
	border-radius: 30px;
	background-color: transparent;
	color: #fe6655;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-lg:hover {
	background-color: #565578;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-warning-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #fe6655;
	border-radius: 30px;
	background-color: transparent;
	color: #fe6655;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-warning-sm:hover {
	background-color: #fe6655;
	color: #ffffff;
	text-decoration: none;
}

.form-floating label {
	margin-left: 0.5rem;
	color: #72719b;
}

.form-floating .form-control {
	padding-left: 1.25rem;
}

.form-control-submit-button {
	display: inline-block;
	width: 100%;
	height: 3.25rem;
	border: 1px solid #fe6655;
	border-radius: 30px;
	background-color: #fe6655;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	cursor: pointer;
	transition: all 0.2s;
}

.form-control-submit-button:hover {
	border: 1px solid #fe6655;
	background-color: transparent;
	color: #fe6655;
}

/* Fade Animation For Rotating Text - ReplaceMe  */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fadeIn {
	/* -webkit-animation: fadeIn 1s; */
	animation: fadeIn 2s;
}

@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
/* end of fade animation for rotating text - replaceme */


/**********************/
/*     Navigation     */
/**********************/
.navbar {
	background-color: #f9f8f8;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
}

.navbar .navbar-brand {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.navbar .navbar-brand:hover {
	text-decoration: none;
}

.navbar .logo-image img {
    width: 132px;
	height: 30px;
}



.offcanvas-collapse {
	position: fixed;
	top: 3rem; /* adjusts the height between the top of the page and the offcanvas menu */
	bottom: 0;
	left: 100%;
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	overflow-y: auto;
	visibility: hidden;
	background-color: #f9f8f8;
	transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.offcanvas-collapse.open {
	visibility: visible;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.navbar .navbar-nav {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.navbar .nav-item .nav-link {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: hsl(200, 19%, 18%);
	text-decoration: none;
	transition: all 0.2s ease;
}

.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
	color: #42cfa5;
}

.trending-topics {
	padding: 20px;
	border: 8px solid  hsla(200, 19%, 18%,.2);
	width: 240px;
  }

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
	animation: fadeDropdown 0.2s; /* required for the fade animation */
}


@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
	}
}

.navbar .dropdown-menu {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	border: none;
	background-color: #f9f8f8;
}

.navbar .dropdown-item {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #263238;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
	text-decoration: none;
}

.navbar .dropdown-item:hover {
	background-color: #f9f8f8;
	color: #42cfa5;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #42cfa5;;
 	text-decoration:none;
 	background-color:#f9f8f8;
}

.navbar .dropdown-divider {
	width: 100%;
	height: 1px;
	margin: 0.5rem auto 0.5rem auto;
	border: none;
	background-color: #d1d1d1;
}
/* end of dropdown menu */


/* mega menu */

.navbar .megamenu { 
	padding: 1rem; 
}


@media all and (min-width: 992px) {
	
	.navbar .has-megamenu {

		position:static !important;
		
	}
	.navbar .megamenu {
		min-width: 180px;
    	margin-top:0; 
	}


	.navbar .two-column {		
		min-width: 180px;
		margin-left: -180px;
    	margin-top:0; 
	}
	
}	


.navbar .megamenu .dropdown-item {

	padding: 0 0;
  	padding-top: 0.25rem;
  	padding-bottom: 0.25rem;
}
.navbar .megamenu .sub-menu__title {
	font-size: .6875rem;
	display: flex;
	align-items: center;
	margin: 0px 10px 10px 0px;
	text-transform: uppercase;
	color: #97999b;
	  }
	


@media (min-width: 992px) {
	.navbar .megamenu .sub-menu__text {
		font-size: .75rem;
		line-height: 1rem;
		margin: 0px 10px 10px 0px;
		width: 250px;
		}
}

@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;
	    margin-top:10px;
	}

	.navbar .megamenu .sub-menu__text {
		font-size: 1rem;
		padding: 0px 0px;
	  }
}
/* end of mega menu */

.navbar .navbar-toggler {
	padding: 0;
	border: none;
	font-size: 1.25rem;
}


.nav-icon {
		display: inline-block;
		border: 1px solid #329ef7;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		padding-left: 5px;
		text-decoration: none;
	
	  }

.nav-icon:hover{
		background-color: #329ef7;
		color: #ffffff;
		text-decoration: none;
	  }

.nav-icon .fas, .nav-icon .far {
		color: #329ef7;
		font-size: 1.2rem;
		line-height: 1.65em;
}

.nav-icon .fas:hover, .nav-icon .far:hover {
		color: #ffffff;
	  }



/******************/
/*     Header     */
/******************/
.header {
	padding-top: 8rem;
	/* padding-bottom: 9rem; */
	background-color: #f9f8f8;
	text-align: center;
}

.header .h1-large {
	margin-bottom: 1.25rem;
}

.bg-overlay {
	background-color: white;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

  }

.bg-overlay-dark {
	background: linear-gradient(to left, #1b0255 0%, #0f7173 100%);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
  }

  .bg-gradient-planblick {
	background: linear-gradient(to left, #1b0255 0%, #0f7173 100%);
  }

.opacity-half {
	opacity: 0.3;
  }

.opacity-80 {
	opacity: 0.8;
  }

.bg-gradient-white {
	background: linear-gradient(to top, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 80%, rgba(255,255,255,0.4) 95%, rgba(255,255,255,0.5));
  }

.bg-gradient-gray {
	background: linear-gradient(to top, rgba(128, 127, 127, 0), rgb(128, 127, 127, 0.7));
  }

.bg-half-170 {
	padding: 170px 0;
	background-size: cover;
	align-self: center;
	position: relative;
	background-position: center center;
  }
  
  .bg-half {
	padding: 200px 0 100px;
	background-size: cover;
	align-self: center;
	position: relative;
	background-position: center center;
  }

.content-block-home {
	padding: 7%;
}

.bg-home {
	height: 100vh;
	background-size: cover;
	align-self: center;
	position: relative;
	background-position: center center;
  }

.home-dashboard img {
	position: relative;
	top: 60px;
	z-index: 1;
	overflow: visible;
  }

.header .replace-me {
	color: #329ef7;
}

.header .replace-me span {
	/* text-decoration: underline;*/
}

.header .p-large {
	margin-bottom: 2.75rem;
}

.header .btn-solid-lg {
	display: block;
	margin-bottom: 1.75rem;
}

.header .text-container {
	margin-bottom: 5rem;
}


/********************/
/*     Features     */
/********************/

.fdb-block .fdb-touch-secondary {
	border-top: solid 0.3125rem #01a89e !important;
	z-index: 2;
  }


.cards-1 {
	padding-top: 9rem;
	padding-bottom: 1.25em;
	text-align: center;
}

.cards-1 .h2-heading {
	margin-bottom: 3.75rem;
}

.cards-1 .h2-heading span {
	color: #fe6655;
	/* text-decoration: underline; */
}

.cards-1 .card {
	margin-bottom: 5rem;
	border: none;
	background-color: transparent;
}

.cards-1 .card-body {
	padding: 0;
}

.cards-1 .card-title {
	margin-bottom: 1rem;
}

.cards-1 .card-body p {
	margin-bottom: 1.125rem;
}

.card-icon {
	width: 76px;
	height: 76px;
	/* margin-right: auto; */
	margin-top: 2rem;
	margin-bottom: 2rem;
	/* margin-left: auto; */
	border-radius: 10px;
	background-color: #efebfd;
	text-align: center;
}

.card-icon .fas,
.card-icon .far {
	color: #6a72e4;
	font-size: 2.5rem;
	line-height: 76px;
}

.card-icon.green {
	background-color: #defaeb;
}

.card-icon.green .fas,
.card-icon.green .far {
	color: #39d5c5;
}

 .card-icon.blue {
	background-color: #e2ebfd;
}

.card-icon.blue .fas,
.card-icon.blue .far {
	color: #329ef7;
}

.card-icon.orange {
	background-color: #f7d7c5;
	;
}

.card-icon.orange .fas,
.card-icon.orange .far {
	color: #fe6655;
}


/*********************/
/*     Details 1     */
/*********************/
.basic-1 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.basic-1 .text-container {
	margin-bottom: 4rem;
	margin-right: 5rem;
}

.basic-1 h2 {
	margin-bottom: 1.875rem;
}

.basic-1 p {
	margin-bottom: 1.875rem;
}

.basic-1 .btn-solid-reg {
	cursor: pointer;
}


/*************************/
/*     Details Modal     */
/*************************/
.modal-dialog {
	max-width: 1150px;
	margin-right: 1rem;
	margin-left: 1rem;
	pointer-events: all;
}

.modal-content {
	padding: 2.75rem 1.25rem;
}

.modal-content .btn-close {
	position: absolute;
	right: 10px;
	top: 10px;
}

.modal-content .image-container {
	margin-bottom: 3rem;
}

.modal-content img {
	border-radius: 6px;
}

.modal-content h3 {
	margin-bottom: 0.5rem;
}

.modal-content hr {
	width: 44px;
	margin-top: 0.125rem;
	margin-bottom: 1.25rem;
	margin-left: 0;
	height: 2px;
	border: none;
	background-color: #53575a;
}

.modal-content h4 {
	margin-top: 2rem;
	margin-bottom: 0.625rem;
}

.modal-content .list-unstyled {
	margin-bottom: 2rem;
}

.modal-content .list-unstyled .fas {
	font-size: 0.75rem;
	line-height: 1.75rem;
}

.modal-content .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}

.modal-content .btn-solid-reg {
	margin-right: 0.5rem;
}

.modal-content .btn-outline-reg {
	cursor: pointer;
}


/*********************/
/*     Details 2     */
/*********************/
.basic-2 {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.basic-2 .image-container {
	margin-bottom: 5rem;
}

.basic-2 h2 {
	margin-bottom: 1.875rem;
}

.basic-2 .list-unstyled .fas {
	font-size: 0.75rem;
	line-height: 1.625rem;
}

.basic-2 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}


/************************/
/*     Testimonials     */
/************************/
.slider-1 {
	padding-top: 9.25rem;
	padding-bottom: 9.75rem;
}

.slider-1 .section-title {
	text-align: center;
}

.slider-1 .h2-heading {
	margin-bottom: 3rem;
	text-align: center;
}

.slider-1 .slider-container {
	position: relative;
}

.slider-1 .swiper-container {
	position: static;
	width: 86%;
	text-align: center;
}

.slider-1 .swiper-button-prev:focus,
.slider-1 .swiper-button-next:focus {
	/* even if you can't see it chrome you can see it on mobile device */
	outline: none;
}

.slider-1 .swiper-button-prev {
	left: -12px;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
	background-size: 18px 28px;
}

.slider-1 .swiper-button-next {
	right: -12px;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23707375'%2F%3E%3C%2Fsvg%3E");
	background-size: 18px 28px;
}

.slider-1 .card {
	position: relative;
	border: none;
	background-color: transparent;
}

.slider-1 .card-image {
	width: 80px;
	height: 80px;
	margin-right: auto;
	margin-bottom: 1.25rem;
	margin-left: auto;
	border-radius: 50%;
}

.slider-1 .card-body {
	padding: 0;
}

.slider-1 .testimonial-text {
	margin-bottom: 1rem;
}

.slider-1 .testimonial-author {
	margin-bottom: 0;
	color: #181c3b;
}


/**********************/
/*     Invitation     */
/**********************/
.basic-3 {
	padding-top: 8rem;
	padding-bottom: 8rem;
	background-color: #263238;
	text-align: center;
}

.basic-3 h4 {
	margin-bottom: 2.375rem;
	color: #ffffff;
}

.basic-3 .btn-outline-lg {
	border-color: #ffffff;
	color: #ffffff;
}

.basic-3 .btn-outline-lg:hover {
	background-color: #ffffff;
	color: #263238;
}


/*******************/
/*     Pricing     */
/*******************/
.cards-2 {
	padding-top: 9rem;
	padding-bottom: 5.25em;
}

.cards-2 .h2-heading {
	margin-bottom: 3.75rem;
	text-align: center;
}

.cards-2 .card {
	margin-bottom: 5rem;
	padding: 4rem 1rem;
	border: 1px solid #9fa3ae;
	border-radius: 20px;
	background-color: #ffffff;
	text-align: center;
}

.cards-2 .card-body {
	padding: 0;
}

.cards-2 .card-title {
	margin-bottom: 2rem;
}

.cards-2 .card-title .decoration-lines {
	width: 30px;
	margin-bottom: 0.25rem;
}

.cards-2 .card-title .decoration-lines.flipped {
	transform: scaleX(-1);
}

.cards-2 .card-title span {
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	color: #263238;
	font-weight: 600;
	font-size: 1.75rem;
	line-height: 2rem;
	letter-spacing: -0.2px;
}

.cards-2 .card-body .list-unstyled {
	margin-bottom: 3rem;
}

.cards-2 .card-body .list-unstyled li {
	margin-bottom: 0.625rem;
}

.cards-2 .card-body .price {
	margin-bottom: 3rem;
	color: #263238;
	font-weight: 600;
	font-size: 3.5rem;
	line-height: 2rem;
	letter-spacing: -0.2px;
}

.cards-2 .card-body .price span {
	font-weight: 400;
	font-size: 1rem;
	line-height: 0;
}


/*********************/
/*     Work Container    */
/*********************/




/*********************/
/*     Questions     */
/*********************/
.accordion-1 {
	padding-top: 9rem;
	padding-bottom: 9rem;
}

.accordion-1 .h2-heading {
	margin-bottom: 1.5rem;
	text-align: center;
}

.accordion-1 .accordion-item {
	padding-bottom: 1rem;
	border: none;
	border-bottom: 1px solid #cdcedd;
}

.accordion-1 .accordion-header {
	width: 100%;
}

.accordion-1 .accordion-button {
	width: auto;
	padding-top: 2rem;
	padding-left: 0;
	background: none;
	box-shadow: none;
	font-weight: 600;
	font-size: 1.15rem;
	line-height: 1.625rem;
}

.accordion-1 .accordion-button:not(.collapsed) {
	color: #212529;
}

.accordion-1 .accordion-button::after,
.accordion-1 .accordion-button:not(.collapsed)::after {
	display: none;
}

.accordion-1 .accordion-button:not(.collapsed)::before {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 436.686 265.011'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23263238;%7d%3c/style%3e%3c/defs%3e%3ctitle%3echevron-down-solid-1%3c/title%3e%3cpath class='cls-1' d='M201.373,257.982L7.03,63.638a24,24,0,0,1,0-33.941L29.7,7.03a24,24,0,0,1,33.9-.04L218.344,161.011,373.089,6.99a24,24,0,0,1,33.9.04L429.657,29.7a24,24,0,0,1,0,33.941L235.315,257.982a24,24,0,0,1-33.942,0h0Z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}

.accordion-1 .accordion-button::before {
	align-self: flex-start;
	flex-shrink: 0;
	width: 1.125rem;
	height: 0.75rem;
	margin-top: 0.5rem;
	margin-right: 0.75rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 436.686 265.011'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23263238;%7d%3c/style%3e%3c/defs%3e%3ctitle%3echevron-down-solid-1%3c/title%3e%3cpath class='cls-1' d='M201.373,257.982L7.03,63.638a24,24,0,0,1,0-33.941L29.7,7.03a24,24,0,0,1,33.9-.04L218.344,161.011,373.089,6.99a24,24,0,0,1,33.9.04L429.657,29.7a24,24,0,0,1,0,33.941L235.315,257.982a24,24,0,0,1-33.942,0h0Z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.125rem;
	transition: transform 0.2s ease-in-out;
}

.accordion-1 .accordion-body {
	padding-top: 0;
	padding-left: 1.875rem;
	padding-bottom: 1.125rem;
}


/******************/
/*     Footer     */
/******************/
.footer {
	padding-top: 6.5rem;
	padding-bottom: 2rem;
	background-color: #263238;
}

.footer a {
	color: #ffffff;
	text-decoration: none;
}

.footer a:hover {
	color: #fe6655;

}


.footer .footer-col {
	margin-bottom: 3rem;
}

.footer h6 {
	margin-bottom: 0.625rem;
	color: #ffffff;
	opacity: 0.8;
}

.footer p,
.footer ul {
	color: #ffffff;
	opacity: 0.8;
}

.footer .li-space-lg li {
	margin-bottom: 0.375rem;
}

.footer .footer-col.third .fa-stack {
	width: 2em;
	margin-bottom: 1.25rem;
	margin-right: 0.375rem;
	font-size: 1.375rem;
}

.footer .footer-col.third .fa-stack .fa-stack-2x {
	color: #ffffff;
	transition: all 0.2s ease;
}

.footer .footer-col.third .fa-stack .fa-stack-1x {
	color: #263238;
	transition: all 0.2s ease;
}

.footer .footer-col.third .fa-stack:hover .fa-stack-2x {
	color: #263238;
}

.footer .footer-col.third .fa-stack:hover .fa-stack-1x {
	color: #ffffff;
}


/*********************/
/*     Copyright     */
/*********************/
.copyright {
	padding-bottom: 1rem;
	background-color: #263238;
	text-align: center;
}

.copyright p,
.copyright a {
	color: #ffffff;
	text-decoration: none;
}

.copyright p {
	opacity: 0.8;
}


/******************************/
/*     Back To Top Button     */
/******************************/
#myBtn {
	position: fixed; 
  	z-index: 99; 
	bottom: 20px; 
	right: 20px; 
	display: none; 
	width: 52px;
	height: 52px;
	border: none; 
	border-radius: 50%; 
	outline: none; 
	background-color: #44434a; 
	cursor: pointer; 
}

#myBtn:hover {
	background-color: #1d1d21;
}

#myBtn img {
	margin-bottom: 0.25rem;
	width: 18px;
}


/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
	padding-top: 9rem;
	padding-bottom: 5rem;
	background-color: #f9f8f8;
}

.ex-header-half {
	padding-top: 1rem;
	padding-bottom: 5rem;
	background-color: #f9f8f8;
}

.ex-basic-1 .list-unstyled .fas {
	font-size: 0.375rem;
	line-height: 1.625rem;
}

.ex-basic-1 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}

.text-box {
	padding: 1.25rem 1.25rem 0.5rem 1.25rem;
	background-color: #f9f8f8;
}

.ex-cards-1 .card {
	border: none;
	background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
	width: 2em;
	font-size: 1.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
	color: #fe6655;
}

.ex-cards-1 .card .fa-stack-1x {
	width: 2em;
	color: #ffffff;
	font-weight: 600;
	line-height: 2.125rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 {
	margin-left: 2.25rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 h5 {
	margin-top: 0.125rem;
	margin-bottom: 0.5rem;
}


.moments {
	background-image: url("../images/moments.jpg") ;
	background-position: center;
	background-repeat: no-repeat;
}

/*************************/
/*     Media Queries     */
/*************************/	
/* Min-width 768px #################################################*/
@media (min-width: 768px) {

	/* Header */
	.header {
		padding-top: 9rem;
	}

	.header .h1-large {
		font-size: 3.25rem;
		line-height: 4.625rem;
	}
	/* end of header */


	/* Extra Pages */
	.ex-basic-1 .text-box {
		padding: 1.75rem 2rem 0.875rem 2rem;
	}
	/* end of extra pages */
}
/* end of min-width 768px */


/* Max-width 768px #################################################*/
@media (max-width: 768px) {

	.fdb-block .col-fill-right {
		width: 100%;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		z-index: 1;
	}

	.fdb-block .overlay-bottom {
		width: 100%;
		position: absolute;
		right: 0;
		top: 300px;
		bottom: 0;
		background-size: cover;
		background-position: center;
		z-index: 1;
	}
	.fdb-block .col-fill-right + div {
		position: inherit;
		z-index: 2;
		top: 100px;
	  }	

	.moments {
		background-image: url("../images/moments-sm.webp") ;
	}

	#home {
		background-image: url('../images/happy-Family-multi-generation-familyplaner-sm.webp') !important;
	}

	#grandparents {
		background-image: url('../images/Grandparent-with-familyplaner.webp') !important;
		background-size: 100% auto !important;
		background-repeat: no-repeat !important;
		background-position: top !important;
	}

	.card-icon {
		width: 50px;
		height: 50px;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.card-icon .fas, .card-icon .far {
		font-size: 1.5rem;
		line-height: 50px;
	  }

}
/* end of max-width 768px */



/* max-width 991px #################################################*/
@media (max-width: 991px) {

	
	h1 {
		font-size: 2rem;
		line-height: 2.625rem;
	}
	
	h2 {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}
	
	h3 {
		font-size: 1.5rem;
		line-height: 2.125rem;
	}
	
	h4 {
		font-size: 1.25rem;
		line-height: 1.625rem;
	}
	
	h5 {
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.375rem;
	}
	
	h6 {
		font-weight: 600;
		font-size: .85rem;
		line-height: 1.2rem;
	}

	.bg-half-170 {
		padding: 50px 0;
	}

	.nav_buttons {
		flex-wrap: wrap;
		display: flex;
		justify-content: center;
	}

}

	/* end of max-width 991px */

/* Min-width 992px #################################################*/
@media (min-width: 992px) {

	.navbar .logo-text {
		color: #263238;
		font-weight: 600;
		font-size: 1.3rem;
		line-height: 1rem;
		text-decoration: none;
	}
	
	/* General Styles */
	.h2-heading {
		width: 33rem;
		margin-right: auto;
		margin-left: auto;
	}

	.p-heading {
		width: 46rem;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of general styles */


	/* Navigation */
	.navbar {
		padding-top: 1.75rem;
		background-color: transparent;
		box-shadow: none;
		transition: all 0.2s;
	}

	.navbar.extra-page {
		padding-top: 0.5rem;
	}

	.navbar.top-nav-collapse {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		background-color: #f9f8f8;
	}

	.offcanvas-collapse {
		position: static;
		top: 0;
		bottom: auto;
		left: auto;
		width: auto;
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		overflow-y: visible;
		visibility: visible;
	}

	.offcanvas-collapse.open {
		-webkit-transform: none;
		transform: none;
	}

	.navbar .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.navbar .nav-item .nav-link {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.navbar .dropdown-menu {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		margin-top: 0;
		box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
	}

	.navbar .dropdown-divider {
		width: 90%;
	}

	.navbar .nav-item .btn-outline-sm {
		margin-top: 0;
		margin-left: 0.625rem;
	}

	/* end of navigation */




	/* Header */

	.header {
		/* overflow: hidden; */
		padding-top: 12rem;
		/* padding-bottom: 12rem; */
		text-align: left;
	}

	.header .image-container, .header  .image-container-lg {
		position: relative;
	}
	
	.header .image-container img {
		position: absolute;
		top: -10px;
		left: 30px;
		width: 500px;
		max-width: none;
	}

	.header .image-container-lg img {
		position: absolute;
		top: -40px;
		left: 30px;
		width: 600px;
		max-width: none;
	}
	/* end of header */


	/* Features */
	.cards-1 .card {
		display: inline-block;
		width: 286px;
		vertical-align: top;
	}
	
	.cards-1 .card:nth-of-type(3n+2) {
		margin-right: 2rem;
		margin-left: 2rem;
	}
	/* end of features */


	/* Details 1 */
	.basic-1 .text-container {
		margin-bottom: 0;
	}
	/* end of details 1 */

	
	/* Details Modal */
	/* Stops body and navbar shift on modal open */
	body.modal-open {
		overflow-y: scroll !important;
		padding-right: 0 !important;
	}

	body.modal-open .navbar {
		padding-right: 0 !important;
	}
	/* end of stops body and navbar shift on modal open */

	.modal {
		padding-right: 0 !important;
	}

	.modal-dialog {
		margin-top: 120px;
		margin-right: auto;
		margin-left: auto;
	}
	
	.modal-content .image-container {
		margin-bottom: 0;
	}
	/* end of details modal */



	/* Details 2 */
	.basic-2 {
		padding-bottom: 5rem;
	}

	.basic-2 .image-container {
		margin-bottom: 0;
		margin-right: auto;
	}
	/* end of details 2 */


	/* Testimonials */
	.slider-1 .swiper-container {
		width: 92%;
	}

	.slider-1 .swiper-button-prev {
		left: -16px;
		width: 22px;
		background-size: 22px 34px;
	}
	
	.slider-1 .swiper-button-next {
		right: -16px;
		width: 22px;
		background-size: 22px 34px;
	}
	/* end of testimonials */


	/* Invitation */
	.basic-3 h4 {
		width: 44rem;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of invitation */


	/* Pricing */
	.cards-2 .card {
		display: inline-block;
		width: 298px;
		vertical-align: top;
	}
	
	.cards-2 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	/* end of pricing */


	/* Questions */
	.accordion-1 .accordion {
		width: 820px;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of questions */
	
	
	/* Footer */
	.footer .footer-col {
		margin-bottom: 2rem;
	}

	.footer .footer-col.first {
		display: inline-block;
		width: 320px;
		margin-right: 1.75rem;
		vertical-align: top;
	}
	
	.footer .footer-col.second {
		display: inline-block;
		width: 320px;
		margin-right: 1.75rem;
		vertical-align: top;
	}
	
	.footer .footer-col.third {
		display: inline-block;
		width: 224px;
		text-align: right;
		vertical-align: top;
	}

	.footer .footer-col.third .fa-stack {
		margin-right: 0;
		margin-left: 0.375rem;
	}
	/* end of footer */


	/* Extra Pages */
	.ex-cards-1 .card {
		display: inline-block;
		width: 296px;
		vertical-align: top;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	/* end of extra pages */
}
/* end of min-width 992px */


/* Min-width 1200px #################################################*/
@media (min-width: 1200px) {

	.navbar .logo-text {
		color: #263238;
		font-weight: 600;
		font-size: 1.875rem;
		line-height: 1rem;
		text-decoration: none;
	}
	
	/* General Styles */
	.container {
		max-width: 1140px;
	}
	/* end of general styles */
	

	/* Header */
	.header {
		/* overflow: hidden; */
		padding-top: 12rem;
		/* padding-bottom: 12rem; */
	}

	.header .image-container {
		position: relative;
	}
	
	.header .image-container img {
		position: absolute;
		top: -10px;
		left: 50px;
		max-width: none;
	}
	/* end of header */


	/* Features */
	.cards-1 .card {
		width: 310px;
	}
	
	.cards-1 .card:nth-of-type(3n+2) {
		margin-right: 5.5rem;
		margin-left: 5.5rem;
	}
	/* end of features */


	/* Details 1 */
	.basic-1 .text-container {
		margin-top: 10rem;
	}

	.basic-1 h2 {
		margin-right: 4rem;
	}

	.basic-1 .image-container {
		margin-left: auto;
	}
	/* end of details 1 */


	/* Details Modal */
	.modal-content {
		padding-right: 2rem;
		padding-left: 2rem;
	}
	/* end of details modal */


	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 5rem;
		margin-left: 5rem;
	}
	/* end of details 2 */


	/* Testimonials */
	.slider-1 .swiper-container {
		width: 96%;
	}

	.slider-1 .swiper-button-prev {
		left: -28px;
	}
	
	.slider-1 .swiper-button-next {
		right: -28px;
	}
	/* end of testimonials */


	/* Pricing */
	.cards-2 .card {
		width: 342px;
	}
	
	.cards-2 .card:nth-of-type(3n+2) {
		margin-right: 2.5rem;
		margin-left: 2.5rem;
	}
	/* end of pricing */


	/* Footer */
	.footer .footer-col.first {
		width: 352px;
		margin-right: 6rem;
	}
	
	.footer .footer-col.second {
		margin-right: 6.5rem;
	}
	
	.footer .footer-col.third {
		text-align: right;
	}
	/* end of footer */


	/* Extra Pages */
	.ex-cards-1 .card {
		width: 336px;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 2.875rem;
		margin-left: 2.875rem;
	}
	/* end of extra pages */
}
/* end of min-width 1200px */


  
.subcribe-form input {
	padding: 12px 20px;
	width: 100%;
	color: #3c4858 !important;
	border: 1px solid #cbcbd1;
	outline: none !important;
	padding-right: 160px;
	padding-left: 30px;
	background-color: rgba(255, 255, 255, 0.8);
	height: 55px; 
  }
  
.subcribe-form button {
	position: absolute;
	top: 0px;
	right: 1px;
	outline: none !important;
  }
  
.subcribe-form form {
	position: relative;
	max-width: 600px;

  }

.btn-solid-subcribe-form {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #263238;
	border-radius: 30px;
	background-color: #263238;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-subcribe-form:hover {
	background-color: transparent;
	color: #263238; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

/* The message box is shown when the user clicks on the password field */
#message {
  display: none;
  color: #031b4e;
  position: relative;
  padding: 10px;
  margin-top: 5px;
}

#message p {
  padding: 0;
  margin: 0;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid-password {
  color: green;
}

.valid-password:before {
  position: relative;
  left: -10px;
  /*content: '\f1ea';*/
  font-family: 'Font Awesome 5 Free';
}

/* Add a red text color and an "x" icon when the requirements are wrong */
.invalid-password {
  color: red;
}

.invalid-password:before {
  position: relative;
  left: -10px;
  /*content: '\f2d3';*/
  font-family: 'Font Awesome 5 Free';
}

.password-combo {
height: 38px;
position: relative;
}

.password-combo .btn {
	position: absolute;
	top: 0;
	right: 0;
	background-color: transparent;
	height: 40px;
	color: #7987a1;
	-webkit-transition: none;
	transition: none;
	font-size: 16px;
	padding-right: 25px;
}



.fdb-block {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  padding: 7.5rem 0;
    padding-top: 7.5rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-color: #FFFFFF;
}

.bg-dark p.lead {
  color: white;
}

#home {
	background: url('../images/Happy-FamilyPlanner.webp') center center; background-size: cover;
}

#grandparents {
	background: url('../images/grandparents-with-laptop.webp') center center; background-size: cover;
}


.note {
	padding: 21.3333333333px;
	text-align: left;
	background: #f7f8f9;
	border-left: 4px solid #647382;
}
.note.tip {
	border-left-color: #01a89e;
}

i.fa-square {
	padding-right: 10px;
  }

/* Diese Angaben gelten nur für die Druckausgabe */
@media print {
	
	.hide-in-print {display:none;}

	#content,#page {
		width: 100%; 
		}

	@page {
        size: A4;
		margin: 2cm; 
      }

	body {
		font: 10pt;
		
		background: #fff !important;
		color: #000;
		}
	h1 {
		font-size: 16pt;
		line-height: 1;
		}

	p, .lead {
		font-size: 10pt;
		margin-bottom: 0;
		line-height: 1.2;
	}
	
			
	h2, h3, h4, h5, .h5 {
		font-size: 12pt;
		margin-bottom: 0;
		line-height: 1;
		}

	.list-unstyled {
			list-style: none;
		  }

	li {

			line-height: 1;
			}
	
	/* a:after {
      content:' (' attr(href) ')';
    } */
	
	h1, h2, h2, h3, h4, h5 {
		page-break-after:avoid; /* Bitte Kompatibilitätshinweis im Artikel beachten */
	}
	
	image {
		page-break-inside:avoid; /* Bitte Kompatibilitätshinweis im Artikel beachten */
	}

}

